// TODO: Rename this project "just two brothers"

import './App.css';
import { useEffect, useState, } from 'react';
import { getStorage, getDownloadURL, listAll, ref } from "firebase/storage";
import ReactPlayer from 'react-player/file';
import ReactLoading from 'react-loading';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons';

// TODO: Probably not the best way to get BG images, but, fuck it
import adventure from './thumbs/adventure.jpg'
import breakingBoxes from './thumbs/breaking-boxes.jpg'
import brotherUpBrotherIn from './thumbs/brother-up-brother-in.jpg'
import buttBoy from './thumbs/butt-boy.jpg';
import defundingThePolice from './thumbs/defunding-the-police.jpg';
import fitAsFuck from './thumbs/fit-as-fuck.jpg';
import heEmerges from './thumbs/he-emerges.jpg';
import jumpscare from './thumbs/jumpscare.jpg';
import picksTheShield from './thumbs/picks-the-shield.jpg';
import pridePrejudiceHot from './thumbs/pride-prejudice-hot.jpg';
import rememberTheirChildren from './thumbs/remember-their-children.jpg';
import sandra from './thumbs/sandra.jpg';
import sbds from './thumbs/SBDS.jpg';
import sunrise from './thumbs/sunrise.jpg';
import toTheRescue from './thumbs/to-the-rescue.jpg';
import upgradedMyJump from './thumbs/upgraded-my-jump.jpg';
import worldWithoutYou from './thumbs/world-without-you.jpg';
import youJustWantedToSayThat from './thumbs/you-just-wanted-to-say-that.jpg';

// TODO: God this feels dumb as hell
const thumbs = new Map();
thumbs.set('adventure', adventure);
thumbs.set('breakingBoxes', breakingBoxes);
thumbs.set('brotherUpBrotherIn', brotherUpBrotherIn);
thumbs.set('buttBoy', buttBoy);
thumbs.set('defundingThePolice', defundingThePolice);
thumbs.set('fitAsFuck', fitAsFuck);
thumbs.set('heEmerges', heEmerges);
thumbs.set('jumpscare', jumpscare);
thumbs.set('picksTheShield', picksTheShield);
thumbs.set('pridePrejudiceHot', pridePrejudiceHot);
thumbs.set('rememberTheirChildren', rememberTheirChildren);
thumbs.set('sandra', sandra);
thumbs.set('SBDS', sbds);
thumbs.set('sunrise', sunrise);
thumbs.set('toTheRescue', toTheRescue);
thumbs.set('upgradedMyJump', upgradedMyJump);
thumbs.set('worldWithoutYou', worldWithoutYou);
thumbs.set('youJustWantedToSayThat', youJustWantedToSayThat);

Modal.setAppElement('#root');

const App = () => {
	const [clipDownloadURLs, setClipDownloadURLs] = useState([]);
	const [isLoadingClips, setIsLoadingClips] = useState(true);
	const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
	const [currentVideoUrl, setCurrentVideoUrl] = useState('');
	const [isLandingModalOpen, setIsLandingModalOpen] = useState(true);
	const [isOculusVideo, setIsOculusVideo] = useState(false);
	const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);

	// TODO: square videos, like oculus screen captures, are resizing poorly on some screen sizes
	const videoModalStyles = {
		 content: {
			position: 'relative' as 'relative',
			border: 0,
			background: 'transparent',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: 0,
			height: 'fit-content',
			inset: 0,
		 }
	};

	const landingModalStyles = {
		overlay: {
			display: 'flex',
			alignItems: 'center',
		},
		content: {
			border: '1px solid white',
			borderRadius: '8px',
			position: 'relative' as 'relative',
			inset: 0,
			background: 'rgb(0, 0, 0, 0.75)',
			color: 'white',
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column' as 'column',
			maxHeight: '90vh',
			overflow: 'auto',
		}
	};

	const kebabize = (str: string) => {
		return str.split('').map((letter, idx) => {
		  return letter.toUpperCase() === letter
		   ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
		   : letter;
		}).join('');
	 }

	const handleNoteModalClick = () => {
		setIsNoteModalOpen(true);
		setIsLandingModalOpen(false);
		setIsVideoModalOpen(false);
	}

	const handleGoBackClick = () => {
		setIsNoteModalOpen(false);
		setIsLandingModalOpen(true);
		setIsVideoModalOpen(false);
	}

	const handleThumbClick = (thumbFileName: string) => {
		const currentVideoUrl = clipDownloadURLs.find((clipDownloadURL: string) => {
			let formattedThumbFileName;

			if (thumbFileName === 'SBDS') {
				formattedThumbFileName = thumbFileName;
			} else {
				formattedThumbFileName = kebabize(thumbFileName);

			}
			return clipDownloadURL.includes(`${formattedThumbFileName}.mp4`);
		}) || '';
		
		// TODO: Hacky way to ensure the one oculus video's square aspect ratio doesn't render shittily
		if (currentVideoUrl.includes('breaking-boxes')) {
			setIsOculusVideo(true);
		} else {
			setIsOculusVideo(false);
		}

		setCurrentVideoUrl(currentVideoUrl);
		setIsVideoModalOpen(true);
	}

	const handleOpenLandingModal = () => {
		setIsLandingModalOpen(true);
		setIsNoteModalOpen(false);
		setIsVideoModalOpen(false);
	}

	// TODO: This is, for obvious reasons, not a robust solution to getting the thumbnails
	const renderVideoGrid = () => {
		const thumbEls: any = [];

		thumbs.forEach((thumb, key) => {
			const bgStyle = {
				backgroundImage: `url('${thumb}')`,
			}

			thumbEls.push(<div className="bg-cover pointer" style={bgStyle} onClick={() => handleThumbClick(key)}></div>);
		});

		return thumbEls;
	}

	useEffect(() => {
		(async () => {
			const storage = getStorage();
			const clipsRef = ref(storage);
			const response = await listAll(clipsRef);
			// https://stackoverflow.com/questions/40140149/use-async-await-with-array-map
			const clipDownloadURLs: any = await Promise.all(response.items.map(async (item) => {
				return await getDownloadURL(ref(storage, item.fullPath));
			}));

			setClipDownloadURLs(clipDownloadURLs);
			setIsLoadingClips(false);
		})();
	}, []);

	return (
		isLoadingClips ? 
			<div className="flex justify-center items-center h-screen w-full bg-[#525252]">
				<ReactLoading type={'spin'} height={'10%'} width={'10%'} />
			</div> : 
			<>
				<FontAwesomeIcon className='fixed text-white top-6 left-6 fa-4x cursor-pointer hover:text-slate-400' icon={faWindowMaximize} onClick={handleOpenLandingModal}/>

				<div className="grid grid-cols-1 overflow-y-scroll auto-rows-[33.3%] bg-[#525252] h-screen w-full sm:grid-cols-2 sm:auto-rows-[50%] md:grid-cols-3">
					{renderVideoGrid()}
				</div>
				
				<Modal isOpen={isVideoModalOpen} onRequestClose={() => {setIsVideoModalOpen(false)}} style={videoModalStyles} className={isOculusVideo ? 'oculus-video' : ''}>
					<ReactPlayer url={currentVideoUrl} controls={true} width='auto' height='auto'/>
				</Modal>

				<Modal isOpen={isLandingModalOpen} onRequestClose={() => {setIsLandingModalOpen(false)}} style={landingModalStyles} className="p-[1.5rem] basis-[90%] md:p-[2rem] md:basis-[60%] lg:basis-[40%]">
					<h2 className="text-center text-[24px] mb-[1rem] xl:text-[38px]">for you, on your birthday</h2>

					<p className="text-center mb-[1rem]">hey there, brother man. i've gathered here, for your viewing pleasure, a collection of some of our finer moments over the last year and change. to view a video, just click on any of these thumbnail squares. to close this modal, and the video modals, just click/tap outside the modal, or hit escape.</p>

					<p className="text-center mb-[1rem]">to bring this window up again, just click or tap the icon in the top left of this page. oh, and make sure to scroll. there's quite a few clips here. we do like to have a good time, you and i.</p>

					<p className="text-center mb-[1rem]">oh, and when you get some time, it'd mean a lot to me if you click this button right down here. i've got some things i want you to know.</p>

					<button onClick={handleNoteModalClick} className="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 border-b-4 border-gray-700 hover:border-gray-500 rounded">give me a click</button>
				</Modal>

				<Modal isOpen={isNoteModalOpen} onRequestClose={() => {setIsNoteModalOpen(false)}} style={landingModalStyles} className="p-[1.5rem] basis-[90%] md:p-[2rem] md:basis-[60%] lg:basis-[40%]">
					<h2 className="text-center text-[24px] mb-[1rem] xl:text-[38px]">a note, for you</h2>

					<p className="text-left mb-[1rem]">so, here you are. 18 years old. 11 long years ago, i turned 18. and, you know what? i don't remember a damn thing about it. so, it is my hope that this little thing i've put together here is something you might remember, fondly, in another 11 years. the silly little moments i've collected here will stay with me in the days to come.</p>

					<p className="text-left mb-[1rem]">trust me when i say i firmly believe that blood (of the covenant) is thicker than water (of the womb). that said, i still find myself wondering, every now and then, if the same blood that runs through both of us might mean that, sometimes, our brains work similarly. i wonder this because if there's any chance you're anything like me, and i'm anything like you, then maybe you, too, struggle with sincerity. not that you don't want to be sincere, but you want to be sincere in a way that doesn't sound painfully trite. it is one of my weaknesses that i'll not say something nice to someone if i feel like i can't say it the right way.</p>

					<p className="text-left mb-[1rem]">so, having said all that, i want you to know i am trying to say something, now, sincerely, to you, and i pray it doesn't sound trite. every day — and i do mean every day — i find myself wonderfully surprised by the man you are. it's strange, perhaps, but i feel towards you as i would a dear friend, and a peer, but, also, the way i imagine i might feel towards a son (now there's something to take to the old therapist).</p>

					<p className="text-left mb-[1rem]">speaking of corny, i've been struggling to find a way to tell you this for awhile now, and this seem as good a place as any: over the last two years, i have found myself listening to your music often. it is the understatement of the century to say that i have a complicated relationship with mom and dad. i'm going to keep my therapist securely employed for a long, long time. but — and here comes the really corny part — your music has genuinely helped put a voice to what i feel. no joke, i've found myself sitting, alone, in my apartment, on bright sunny spring days, listening to "just love you" and crying to myself. it's catharsis i never thought i'd get, pure and simple. that's the truth, and you should know it.</p>

					<p className="text-left mb-[1rem]">brother man, let me tell you, i have a hard time finding people i want to give my time to. right now, i have a grand total of exactly 5 people i spend any amount of time with, and you are one of them (kyle, sofia, ranya, zoë, for the curious). that you are a young man of 18, and that i genuinely seek out and enjoy your company, is no small miracle. yes, you are my family, but hopefully you have seen that i don't keep people around out of pure familial obligation.</p>
					
					<p className="text-left mb-[1rem]">i keep you, and i choose you, because you are a good man. one of the best i know, and you still have so much room to grow. if i was half the man you are now when i was 18, well, there are some stupid things i've done that i probably wouldn't have. i hope i keep being a man you want to know, because knowing you is one of the gentle joys of my life.</p>

					<p className="w-full text-right mb-[0.5rem]">happy birthday.</p>

					<p className="w-full text-right">your brother, who loves you,</p>	
					<p className="w-full text-right mb-[1rem]">gabriel</p>	

					<button onClick={handleGoBackClick} className="bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 border-b-4 border-gray-700 hover:border-gray-500 rounded">go back</button>			
				</Modal>
			</>
	);
}

export default App;
