import { initializeApp } from 'firebase/app';

const initializeFirebase = () => {
	const firebaseConfig = {
		apiKey: "AIzaSyCQalEsd9bta9hJPpd7EyUBbkFesEatPyE",
		authDomain: "project-big-man.firebaseapp.com",
		projectId: "project-big-man",
		storageBucket: "project-big-man.appspot.com",
		messagingSenderId: "690050970746",
		appId: "1:690050970746:web:abd7e25723124df54677c3"
	  };

	// Initialize Firebase
	const app = initializeApp(firebaseConfig);

	return {
		app,
	};
};

export default initializeFirebase;
